import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PrimaryButton, SecondaryButton } from '../../../components';
import { useHistory } from 'react-router-dom';

import css from './SignupForm.module.css';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const StepPricing = props => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, values, form } = props;

  const [selectedPlan, setSelectedPlan] = useState();
  const history = useHistory();

  const pricingCardsContent = [
    {
      value: 'Basic',
      heading: intl.formatMessage({ id: 'AuthenticationPage.pricingPlanBasicHeading' }),
      price: intl.formatMessage({ id: 'SignupForm.pricing.Basic.price' }),
      subHeading: intl.formatMessage({ id: 'AuthenticationPage.pricingPlanBasicSubHeading' }),
      options: [true, true, true, true, true],
    },
    {
      value: 'Professional',
      heading: intl.formatMessage({ id: 'AuthenticationPage.pricingPlanProfessionalHeading' }),
      price: intl.formatMessage({ id: 'SignupForm.pricing.Professional.price' }),
      subHeading: intl.formatMessage({
        id: 'AuthenticationPage.pricingPlanProfessionalSubHeading',
      }),
      options: [true, true, true, true, true],
    },
    {
      value: 'Enterprise',
      heading: intl.formatMessage({ id: 'AuthenticationPage.pricingPlanEnterpriseHeading' }),
      price: intl.formatMessage({ id: 'SignupForm.pricing.Enterprise.price' }),
      subHeading: intl.formatMessage({ id: 'AuthenticationPage.pricingPlanEnterpriseSubHeading' }),
      options: [true, true, true, true, true],
    },
  ];

  return (
    <>
      <header className={css.sectionDetails}>
        <h2 className={css.title}>
          {' '}
          <FormattedMessage id="SignupForm.stepPricing.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="SignupForm.stepPricing.description" />
        </p>
      </header>

      <div className={css.pricingContainer}>
        <div className={css.pricingSegment}>
          {pricingCardsContent.map(pricing => (
            <div key={pricing.value} className={classNames(css.pricingCard)}>
              <div className={css.pricingHeading}>{pricing.heading}</div>
              <div className={css.pricingPrice}>{pricing.price}</div>
              <div className={css.pricingSubHeading}>{pricing.subHeading}</div>
              <div className={css.checksSegment}>
                {pricing.options.map((checked, index) => (
                  <div className={css.checkContainer} key={pricing + '.' + index}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={checked ? '#89f0bc' : '#888'}
                      className={css.checkIcon}
                    />
                    <span
                      className={css.checkText}
                      style={{
                        color:
                          selectedPlan === pricing.value && checked
                            ? 'black'
                            : checked
                            ? 'black'
                            : '#888',
                      }}
                    >
                      {intl.formatMessage({
                        id: `SignupForm.pricing.${pricing.value}.option${index + 1}`,
                      })}
                    </span>
                  </div>
                ))}
              </div>
              <div className={css.spacer}></div>
              {pricing.value === 'Enterprise' ? (
                <PrimaryButton
                  className={css.userTypeBtn}
                  type="button"
                  onClick={() => {
                    history.push('/p/kontakt');
                  }}
                >
                  <FormattedMessage id="SignupForm.stepPricing.contactSalesButton" />
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className={css.userTypeBtn}
                  type="button"
                  onClick={() => {
                    setSelectedPlan(pricing.value);
                    form.change('plan', pricing.value);
                    form.change('userType', 'lessor');
                    props.nextStep();
                  }}
                >
                  <FormattedMessage id="SignupForm.stepPricing.selectButton" />
                </PrimaryButton>
              )}
            </div>
          ))}
        </div>
        <div className={css.buttonSegment}>
          <SecondaryButton
            className={css.backBtn}
            type="button"
            onClick={() => {
              props.goToNamedStep('userType');
            }}
          >
            <FormattedMessage id="SignupForm.ButtonBack" />
          </SecondaryButton>
        </div>
      </div>
    </>
  );
};

export default StepPricing;
