import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CustomExtendedDataField,
  FieldTextInput,
  FieldVATInput,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';

import { required } from '../../../util/validators';
import css from './SignupForm.module.css';
import classNames from 'classnames';
import { validateVAT } from '../../../util/api';
import * as validators from '../../../util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import UserFieldDisplayName from '../UserFieldDisplayName';
import { getUserFieldConfigByKey } from '../../../util/userHelpers';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';
import FieldSelectUserType from '../FieldSelectUserType';
import PasswordStrengthBar from 'react-password-strength-bar';

const EU_COUNTRY_CODES = ['AT', 'DE', 'CHE'];

const StepPersonalDetails = props => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const {
    fieldId,
    values,
    form,
    formId,
    userTypes,
    userFields,
    invalid,
    termsAndConditions,
    submitDisabled,
  } = props;

  const [customVatErrorText, setCustomVatErrorText] = useState('');
  const [vatNumberVerifying, setVatNumberVerifying] = useState(false);

  const vatNumberLabel = intl.formatMessage({ id: 'SignupForm.vatNumberLabel' });
  const vatNumberPlaceholder = intl.formatMessage({ id: 'SignupForm.vatNumberPlaceholder' });

  const validateVATNumber = async value => {
    var countryCode = '';
    var vatNumber = '';

    //swiss check
    if (value.startsWith('CHE')) {
      const cleanedVAT = value
        ?.toUpperCase()
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replace('MWST', '')
        .trim()
        .replace('MWST', '')
        .replace('TVA', '')
        .replace('IVA', '');

      countryCode = value?.substring?.(0, 3);
      vatNumber = cleanedVAT;
    } else {
      countryCode = value?.substring?.(0, 2);
      vatNumber = value.toUpperCase().trim();
    }

    const invalidLength = validators.minLength(
      intl.formatMessage({ id: 'FieldVATInput.minLengthError' }),
      10
    )(value);

    if (invalidLength) {
      handleVatVerified(false);
      return invalidLength;
    }

    // check if the country code is valid
    if (!EU_COUNTRY_CODES.includes(countryCode)) {
      handleVatVerified(false);
      return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
    }

    if (!vatNumber || !countryCode) {
      return undefined; // No validation error if the VAT number or country code is empty
    }

    try {
      const response = await validateVAT({
        countryCode,
        vatNumber,
      });

      if (response.valid == null) {
        return intl.formatMessage({ id: 'FieldVATInput.validationError' });
      }

      handleVatVerified(response.valid);

      if (response.valid) {
        return undefined;
      } else {
        return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
      }
    } catch (error) {
      if (error.code == 'INVALID_INPUT') {
        return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
      }

      return intl.formatMessage({ id: 'FieldVATInput.validationError' });
    }
  };

  const handleVatVerified = vatVerified => {
    console.log('vatVerified=', vatVerified);
    form.batch(() => {
      form.change('vatVerified', vatVerified);
    });
  };

  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'SignupForm.emailRequired',
    })
  );
  const emailValid = validators.emailFormatValid(
    intl.formatMessage({
      id: 'SignupForm.emailInvalid',
    })
  );

  const passwordToShort = intl.formatMessage({
    id: 'SignupForm.password.tooShort',
  });

  const passwordWeak = intl.formatMessage({
    id: 'SignupForm.password.weak',
  });

  const passwordOk = intl.formatMessage({
    id: 'SignupForm.password.ok',
  });

  const passwordStrong = intl.formatMessage({
    id: 'SignupForm.password.strong',
  });

  const passwordVeryStrong = intl.formatMessage({
    id: 'SignupForm.password.veryStrong',
  });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordSpecialCharacters = validators.passwordSpecialCharacters(
    intl.formatMessage({
      id: 'SignupForm.passwordSpecialCharacters',
    })
  );
  const passwordContainsNumber = validators.passwordContainsNumber(
    intl.formatMessage({
      id: 'SignupForm.passwordContainsNumber',
    })
  );
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength,
    passwordSpecialCharacters,
    passwordContainsNumber
  );

  return (
    <>
      <header className={css.sectionDetails}>
        <h2 className={css.title}>
          {' '}
          <FormattedMessage id="SignupForm.stepPersonalDetails.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="SignupForm.stepPersonalDetails.description" />
        </p>
      </header>
      <div className={css.pricingContainer}>
        <div className={css.innerForm}>
          <CustomExtendedDataField
            {...getUserFieldConfigByKey({ key: 'salutation', userFields, intl })}
            formId={formId}
            className={css.row}
          />
          <div className={css.name}>
            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.firstNameRequired',
                })
              )}
            />
            <FieldTextInput
              className={css.lastNameRoot}
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.lastNameRequired',
                })
              )}
            />
          </div>

          <UserFieldPhoneNumber
            formName="SignupForm"
            className={css.row}
            userTypeConfig={{
              phoneNumberSettings: {
                displayInSignUp: true,
                required: true,
              },
            }}
            intl={intl}
          />
          <FieldTextInput
            className={css.row}
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            label={intl.formatMessage({
              id: 'SignupForm.emailLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          <FieldTextInput
            className={css.password}
            type="password"
            id={formId ? `${formId}.password` : 'password'}
            name="password"
            autoComplete="new-password"
            label={intl.formatMessage({
              id: 'SignupForm.passwordLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.passwordPlaceholder',
            })}
            validate={passwordValidators}
          />
          <PasswordStrengthBar
            password={values.password}
            scoreWords={[
              passwordWeak,
              passwordWeak,
              passwordOk,
              passwordStrong,
              passwordVeryStrong,
            ]}
            minLength={validators.PASSWORD_MIN_LENGTH}
            shortScoreWord={passwordToShort}
          />

          <div className={css.termsWrapper}>{termsAndConditions}</div>
        </div>

        <div className={css.buttonSegmentCenter}>
          <SecondaryButton
            className={css.backBtn}
            type="button"
            onClick={() => {
              props.onClearSignupErrors();
              props.goToNamedStep('companyDetails');
            }}
          >
            <FormattedMessage id="SignupForm.ButtonBack" />
          </SecondaryButton>
          <PrimaryButton
            className={css.nextBtn}
            type="submit"
            inProgress={vatNumberVerifying}
            disabled={submitDisabled}
          >
            <FormattedMessage id="SignupForm.signUp" />
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default StepPersonalDetails;
