import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../../components';
import { ReactComponent as Tenant } from './assets/Rentor.svg';
import { ReactComponent as Lessor } from './assets/Lessor.svg';

import css from './SignupForm.module.css';
import classNames from 'classnames';

const Step = props => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, values, form } = props;

  return (
    <>
      <header className={css.sectionDetails}>
        <h2 className={css.title}>
          {' '}
          <FormattedMessage id="SignupForm.stepUserType.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="SignupForm.stepUserType.description" />
        </p>
      </header>

      <div className={css.pricingContainer}>
        <div className={css.pricingSegment}>
          <div className={classNames(css.userTypeCard)}>
            <div className={css.userTypeHeading}>
              <FormattedMessage id="SignupForm.userType.tenant.title" />
            </div>

            <div className={css.userTypeIcon}>
              <Tenant />
            </div>

            <PrimaryButton
              className={css.userTypeBtn}
              type="button"
              onClick={() => {
                form.change('userType', 'tenant');
                form.change('plan', '');
                props.goToNamedStep('companyDetails');
              }}
            >
              <FormattedMessage id="SignupForm.userType.tenant.button" />
            </PrimaryButton>
          </div>
          <div className={classNames(css.userTypeCard)}>
            <div className={css.userTypeHeading}>
              <FormattedMessage id="SignupForm.userType.lessor.title" />
            </div>

            <div className={css.userTypeIcon}>
              <Lessor />
            </div>

            <PrimaryButton
              className={css.userTypeBtn}
              type="button"
              onClick={() => {
                form.change('userType', 'lessor');
                props.goToNamedStep('pricing');
              }}
            >
              <FormattedMessage id="SignupForm.userType.lessor.button" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step;
