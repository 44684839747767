import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import PasswordStrengthBar from 'react-password-strength-bar';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getUserFieldConfigByKey } from '../../../util/userHelpers';
import StepWizard from 'react-step-wizard';
import StepUserType from './SignupStepUserType';
import Nav from './Nav';

import { Form } from '../../../components';

import css from './SignupForm.module.css';

import StepPricing from './SignupStepPricing';
import StepCompanyDetails from './SignupStepCompanyDetails';
import StepPersonalDetails from './SignupStepPersonalDetails';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

// Hidden input field

const SignupFormComponent = props => {
  const [customVatErrorText, setCustomVatErrorText] = useState('');
  const [vatNumberVerifying, setVatNumberVerifying] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const type = queryParams.get('typ');

  const [currentStep, setCurrentStep] = useState(type && type === 'vermieter' ? 2 : 1);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={{
        userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          preselectedUserType, // eslint-disable-line no-unused-vars
          userTypes,
          userFields,
          values,
          form,
        } = formRenderProps;

        const { userType } = values || {};

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress || vatNumberVerifying;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={async e => {
              e?.preventDefault();
              handleSubmit(values);
            }}
          >
            <StepWizard
              initialStep={currentStep}
              onStepChange={steps => {
                setCurrentStep(steps.activeStep);
              }}
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
              }}
            >
              <StepUserType
                fieldId={'vatCheck'}
                stepNam={'vatCheck'}
                values={values}
                form={form}
                stepName="userType"
              />
              <StepPricing fieldId={'pricing'} values={values} form={form} stepName="pricing" />
              <StepCompanyDetails
                fieldId={'companyDetails'}
                values={values}
                userTypes={userTypes}
                form={form}
                formId={formId}
                userFields={userFields}
                stepName="companyDetails"
                invalid={invalid}
                onClearSignupErrors={props.onClearSignupErrors}
              />
              <StepPersonalDetails
                fieldId={'personalDetails'}
                values={values}
                userTypes={userTypes}
                form={form}
                formId={formId}
                userFields={userFields}
                stepName="personalDetails"
                invalid={invalid}
                termsAndConditions={termsAndConditions}
                submitDisabled={submitDisabled}
                onClearSignupErrors={props.onClearSignupErrors}
              />
            </StepWizard>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
