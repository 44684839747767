import React, { useEffect, useState, useRef } from 'react';
import { func, string } from 'prop-types';

import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { FieldCheckboxGroup } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import classNames from 'classnames';
import css from './TermsAndConditions.module.css';

const KEY_CODE_ENTER = 13;

const TermsAndConditions = props => {
  const { onOpenTermsOfService, onOpenPrivacyPolicy, formId, intl } = props;

  const [visiblePopups, setVisiblePopups] = useState({
    companyPopup: false,
  });

  const companyPopupRef = useRef(null);


  const handleClickOutside = (event) => {
    if (companyPopupRef.current && !companyPopupRef.current.contains(event.target)) {

      setVisiblePopups({
        companyPopup: false,
      });
    }
  };

  useEffect(() => {
    if (visiblePopups.companyPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visiblePopups]);

  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };

  const termsLink = (
    <span
      className={css.termsLink}
      onClick={handleClick(onOpenTermsOfService)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenTermsOfService)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsCondLinkText" />
    </span>
  );

  const generalTermsLink = (
    <span
      className={css.termsLink}
      onClick={handleClick(onOpenTermsOfService)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenTermsOfService)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsGeneralTermsLinkText" />
    </span>
  );

  const privacyLink = (
    <span
      className={css.privacyLink}
      onClick={handleClick(onOpenPrivacyPolicy)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenPrivacyPolicy)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </span>
  );

  const companyLink = (
    <span className={css.termsLink} onClick={() => setVisiblePopups({ companyPopup: true })} role="button" tabIndex="0">
      {intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsCompanyLinkText' })}
    </span>
  );

  return (
    <div className={classNames(css.root,css.generalTermsText)}>
      <FieldCheckboxGroup
        name="terms"
        id={formId ? `${formId}.terms-accepted` : 'terms-accepted'}
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptLabelText' },
              { termsLink, companyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage(
            { id: 'AuthenticationPage.termsAndConditionsAcceptRequired' },
          )
        )}
        showValidationError={false}
      />
      {visiblePopups.companyPopup && (
        <div ref={companyPopupRef} className={css.popupContainer}>
          <div className={css.popupHeader}>
            <FontAwesomeIcon
              icon={faXmark}
              className={css.popupCloseIcon}
              onClick={() => setVisiblePopups({ companyPopup: false })}
            />
          </div>
          <div className={css.popupContent}>
            <FormattedMessage id="AuthenticationPage.companyPolicy" />
          </div>
        </div>
      )}

      <div className={css.finePrint}>
        <FormattedMessage
          id="AuthenticationPage.termsAndConditionsAcceptLinkText"
          values={{ privacyLink, generalTermsLink }}
        />
      </div>

    </div>
  );
};

TermsAndConditions.defaultProps = {
  formId: null,
};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  formId: string,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
