import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  CustomExtendedDataField,
  FieldTextInput,
  FieldVATInput,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';

import { required } from '../../../util/validators';
import css from './SignupForm.module.css';
import classNames from 'classnames';
import { validateVAT } from '../../../util/api';
import * as validators from '../../../util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import UserFieldDisplayName from '../UserFieldDisplayName';
import { getUserFieldConfigByKey } from '../../../util/userHelpers';

const EU_COUNTRY_CODES = ['AT', 'DE', 'CHE'];

const StepCompanyDetails = props => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const { fieldId, values, form, formId, userTypes, userFields, invalid } = props;

  const [customVatErrorText, setCustomVatErrorText] = useState('');
  const [vatNumberVerifying, setVatNumberVerifying] = useState(false);

  const vatNumberLabel = intl.formatMessage({ id: 'SignupForm.vatNumberLabel' });
  const vatNumberPlaceholder = intl.formatMessage({ id: 'SignupForm.vatNumberPlaceholder' });

  const validateVATNumber = async value => {
    var countryCode = '';
    var vatNumber = '';

    //swiss check
    if (value.startsWith('CHE')) {
      const cleanedVAT = value
        ?.toUpperCase()
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replace('MWST', '')
        .trim()
        .replace('MWST', '')
        .replace('TVA', '')
        .replace('IVA', '');

      countryCode = value?.substring?.(0, 3);
      vatNumber = cleanedVAT;
    } else {
      countryCode = value?.substring?.(0, 2);
      vatNumber = value.toUpperCase().trim();
    }

    const invalidLength = validators.minLength(
      intl.formatMessage({ id: 'FieldVATInput.minLengthError' }),
      10
    )(value);

    if (invalidLength) {
      handleVatVerified(false);
      return invalidLength;
    }

    // check if the country code is valid
    if (!EU_COUNTRY_CODES.includes(countryCode)) {
      handleVatVerified(false);
      return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
    }

    if (!vatNumber || !countryCode) {
      return undefined; // No validation error if the VAT number or country code is empty
    }

    try {
      const response = await validateVAT({
        countryCode,
        vatNumber,
      });

      if (response.valid == null) {
        return intl.formatMessage({ id: 'FieldVATInput.validationError' });
      }

      handleVatVerified(response.valid);

      if (response.valid) {
        return undefined;
      } else {
        return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
      }
    } catch (error) {
      if (error.code == 'INVALID_INPUT') {
        return intl.formatMessage({ id: 'FieldVATInput.invalidVAT' });
      }

      return intl.formatMessage({ id: 'FieldVATInput.validationError' });
    }
  };

  const handleVatVerified = vatVerified => {
    console.log('vatVerified=', vatVerified);
    form.batch(() => {
      form.change('vatVerified', vatVerified);
    });
  };

  const userTypeConfig = userTypes.find(config => config.userType === values.userType);

  const isSubmitDisabled =
    !values.displayName ||
    !values.prot_addressCity ||
    !values.prot_addressPostalcode ||
    !values.prot_addressCountry ||
    !values.prot_addressStreet ||
    !values.prot_addressStreetNumber ||
    !values.vatNumber;

  return (
    <>
      <header className={css.sectionDetails}>
        <h2 className={css.title}>
          {' '}
          <FormattedMessage id="SignupForm.stepCompanyDetails.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="SignupForm.stepCompanyDetails.description" />
        </p>
      </header>

      <div className={css.pricingContainer}>
        <div className={css.innerForm}>
          <UserFieldDisplayName
            formName="SignupForm"
            className={css.row}
            userTypeConfig={userTypeConfig}
            intl={intl}
          />

          <FieldVATInput
            className={css.row}
            name="vatNumber"
            id="vatNumber"
            label={vatNumberLabel}
            placeholder={vatNumberPlaceholder}
            customErrorText={customVatErrorText}
            // handleVatVerified={handleVatVerified}
          />
          <FieldTextInput type="hidden" id="vatVerified" name="vatVerified" />

          <div className={css.name}>
            <CustomExtendedDataField
              {...getUserFieldConfigByKey({ key: 'addressStreet', userFields, intl })}
              formId={formId}
              forceText={true}
              className={css.street}
            />
            <CustomExtendedDataField
              {...getUserFieldConfigByKey({ key: 'addressStreetNumber', userFields, intl })}
              formId={formId}
              forceText={true}
              className={css.streetnumber}
            />
          </div>
          <div className={css.name}>
            <CustomExtendedDataField
              {...getUserFieldConfigByKey({ key: 'addressPostalcode', userFields, intl })}
              formId={formId}
              forceText={true}
              className={css.addressPostalcode}
            />
            <CustomExtendedDataField
              {...getUserFieldConfigByKey({ key: 'addressCity', userFields, intl })}
              formId={formId}
              forceText={true}
              className={css.addressCity}
            />
          </div>
          <CustomExtendedDataField
            {...getUserFieldConfigByKey({ key: 'addressCountry', userFields, intl })}
            formId={formId}
          />
          <div className={css.addressCountry}></div>
        </div>
        <div className={css.buttonSegmentCenter}>
          <SecondaryButton
            className={css.backBtn}
            type="button"
            onClick={() => {
              props.onClearSignupErrors();
              if (values.userType === 'lessor') {
                props.goToNamedStep('pricing');
              } else {
                props.goToNamedStep('userType');
              }
            }}
          >
            <FormattedMessage id="SignupForm.ButtonBack" />
          </SecondaryButton>
          <PrimaryButton
            className={css.nextBtn}
            type="button"
            inProgress={vatNumberVerifying}
            disabled={isSubmitDisabled}
            onClick={async () => {
              setVatNumberVerifying(true);
              const hasError = await validateVATNumber(values.vatNumber);
              if (hasError) {
                setCustomVatErrorText(hasError);
                setVatNumberVerifying(false);
                return;
              } else {
                setCustomVatErrorText('');
                setVatNumberVerifying(false);
                props.goToNamedStep('personalDetails');
              }
            }}
          >
            <FormattedMessage id="SignupForm.ButtonNext" />
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default StepCompanyDetails;
